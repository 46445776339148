//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import api from "../api.js";
export default {
  data: () => ({
    itemList: [],
    elementList: [],
    elementSelected: {
      id: 0,
      description: "",
    },
  }),
  created() {},
  mounted() {
    this.getElementList(parseInt(sessionStorage["sessElementId"]));
    this.getItemList(parseInt(sessionStorage["sessElementId"]));
  },
  methods: {
    goBack() {
      this.$router.push("/workcalendaredit");
    },
    goMenu() {
      this.$router.push("/mainmenu");
    },
    getStyle(state) {
      if (state != "A") {
        return "color: green";
      } else {
        return "color: black";
      }
    },
    omplirId(eId) {
      for (var i = 0; i < this.elementList.length; i++) {
        if (this.elementList[i].id == eId) {
          this.elementSelected.id = eId;
          this.elementSelected.description = this.elementList[i].description;
        }
      }
    },
    getElementList(eId) {
      axios
        .post(api.URL() + "/api/v1/gcontacteselements_getitemsbycustomer", {
          token: sessionStorage.getItem("Token"),
          customerId: parseInt(sessionStorage["sessCustomerId"]),
          elementId: eId,
        })
        .then((response) => {
          this.elementList = response.data;
          if (response.error == null) {
            this.omplirId(eId);
          }
        })
        .catch(function (error) {
          this.msgAlert(error.response, true);
        });
    },
    getItemList(eId) {
      axios
        .post(api.URL() + "/api/v1/gordresfabricacio00_getitemsbycustomer", {
          token: sessionStorage.getItem("Token"),
          customerId: parseInt(sessionStorage["sessCustomerId"]),
          elementId: eId,
        })
        .then((response) => {
          if (response.error == null) {
            this.itemList = response.data;
          }
        })
        .catch(function (error) {
          this.msgAlert(error.response, true);
        });
    },
    goEdit(docLineId02) {
      sessionStorage["sessDocLineId02"] = parseInt(docLineId02);
      //this.$router.push("/workorder02edit");
      //  this.elementSelected.id = 2; //= [`id: 1, description: "PC AMD "`];
      //  this.elementSelected.description = "PC AMD 2";
      //this.elementSelected.description = "PC AMD 2";
      //console.log(this.elementSelected);
    },
    msgAlert(missatge, close) {
      var onClose = () => {
        if (close && close == true) {
          this.$router.push("/");
        }
      };
      this.$alert.show({ message: missatge, onClose: onClose });
    },
  },
};
