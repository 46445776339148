import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VCard,[_c(VToolbar,{attrs:{"color":"blue lighten-2","cards":"","dark":"","flat":""}},[_c(VBtn,{attrs:{"color":"blue lighten-2"},on:{"click":function($event){return _vm.goBack()}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VSpacer),_vm._v(" Historial "),_c(VSpacer),_c(VBtn,{attrs:{"color":"blue lighten-2"},on:{"click":function($event){return _vm.goMenu()}}},[_c(VIcon,[_vm._v("mdi-exit-to-app")])],1)],1),_c(VCombobox,{staticClass:"pa-4",attrs:{"clearable":"","label":"Element","items":_vm.elementList,"item-text":"description","item-value":"id"},on:{"change":function($event){return _vm.getItemList(_vm.elementSelected.id)}},model:{value:(_vm.elementSelected),callback:function ($$v) {_vm.elementSelected=$$v},expression:"elementSelected"}}),_c(VSimpleTable,[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Data")]),_c('th',{staticClass:"text-left"},[_vm._v("Descripció")])])]),_c('tbody',_vm._l((_vm.itemList),function(item){return _c('tr',{key:item.uKey,staticClass:"table-primary",on:{"click":function($event){return _vm.goEdit(item.docId)}}},[_c('td',{staticClass:"text-left"},[_c('p',{style:(_vm.getStyle(("" + (item.state))))},[_vm._v(_vm._s(item.docDate))])]),_c('td',{staticClass:"text-left"},[_c('p',{style:(_vm.getStyle(("" + (item.state))))},[_vm._v(_vm._s(item.description))])])])}),0)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }